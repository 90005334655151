.header {
  position: relative;
  width: 100%;
  height: auto;
  padding : 10% 0;
  overflow: hidden !important;

}

.headerFull {
  height: 100vh;
  width: 100%;
  overflow: hidden !important;
}

.video {
  position: absolute;
  width: 100vw;
  top :-30%;
  filter: brightness(50%);
  z-index: -1;
}

@media screen and (max-width:1024px){
  .video {
    top :-15%;
  }

  .header {
    padding : 8% 0;
  }
}

.headTitle {
  font-weight: bold;
  font-size: 64px;
  line-height: 68px;
  /* or 106% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #ffffff;
}
.headTitle2 {
  font-weight: bold;
  font-size: 64px;
  line-height: 68px;
  /* or 106% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #d509fd;
}

.headDesc {
  width: 400px;
  font-size: 25px;
  line-height: 28px;
  /* or 162% */

  color: #ffffff;
}
.headBtn {
  background: var(--graidentColor);
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 8px 25px !important;
  letter-spacing: 0.75px;
  color: #fff !important;
  text-decoration: none;
}

.headBtn:hover{
   border: 1px solid;
  box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}

@media screen and (max-width: 800px) {

  .mobileGraident{
    position: absolute;
    width: 100%;
    height: 100%;
    background:radial-gradient(ellipse at 50% 50%, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0) 85%);
    z-index: -1;
  }

  .headContainer{
    z-index: 1 !important;
  }
  
  .header {
    position: relative;
    background: url(../../images/topLayer.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: calc(100vh - 80px);
    padding: 0;
    z-index: 1;
  }
  
  .headTitle {
    width: 100%;
    text-align: center !important;
    display: inline-block;
  }

  .headDesc {
    z-index: 15 !important;
    text-align: center;
    width: 100%;
    padding: 15px 5%;
  }
}
